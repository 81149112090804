<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="content content-halfpadding content-hasfooter workshops">
    <div v-if="post">
      <router-link 
              tag="div"
              class="workshops-card"
              v-bind:key="key" 
              :to="{ name: 'Workshop', params: { lang: $root.language[$root.languageid], type: 'Workshop', name: row.Contribution.Name,  id: row.Contribution.Id }}"
              v-for="(row, key) in post.Documents"
          >
                  <div class="card-padding-bottom">
                    <h1 v-html="row.Data.Bezeichnung.Content[$root.languageid]"></h1>
                    <div class="imgcontainer imgcontainer-margin-top imgcontainer-margin-bottom">
                      <div :style="{backgroundImage: `url(${row.Data.Bild.Content[0].Files.Resized[2]})`}"></div>
                    </div>

                    <!--<p class="p-medium" >
                      <router-link 
                        v-bind:key="key2" v-for="(row2, key2) in row.Data.Unterseiten.Content"
                        tag="a"
                        v-if="row.Data.Unterseiten.Reference && row.Data.Unterseiten.Reference[row2]  && row.Data.Unterseiten.Reference[row2].Bezeichnung"
                        :to="{ name: 'Workshop', params: { lang: $root.language[$root.languageid], type: 'Workshop', name: $root.u(row.Contribution.Name),  id:row.Contribution.Id , subid: row2, subname: $root.u(row.Data.Unterseiten.Reference[row2].Bezeichnung.Parsed[$root.languageid]) }}"
                        v-html="row.Data.Unterseiten.Reference[row2].Bezeichnung.Parsed[$root.languageid]"
                      >
                      </router-link> 
                    </p>-->
                  </div>
          </router-link>  
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
      loading: false,
      post: null,
      error: null
    }
  },
  created () {
    if (this.$route.name === 'Workshops') {
        this.post = null
        this.fetchData()
    }
  },
  watch: {
    '$route' () {
        if (this.$route.name === 'Workshops') {
          this.error = this.post = null
          this.fetchData()          
        }
    }
  },
  methods: {
    fetchData() {
      this.loading = true
      try {
        this.$store.dispatch('loadData', {
          mode: 'contributions', 
          issue: this.$route.meta.issue.Id, 
          chapter: this.$route.meta.chapter.Id
        }).then((data) => {
          this.post = data
          this.loading = false;
          this.$root.addBreadcrumb(this.$route.meta.translation);
          this.$root.applyMeta(`${this.$route.meta.translation[this.$root.languageid]} - ${this.$root.metatags.title[this.$root.languageid]}`)            

        });  
      } catch (error) {
        this.error = error;
      }      
    }
  }
}
</script>


<style lang="scss">

  @import "../main";

  .workshops {
    padding-left: $margin-standard / 2;
    padding-right: $margin-standard / 2;
    &-card {
      display: inline-block;
      width: 33.3%;
      cursor: pointer;
      
      @include st() {
        width: 50%;
      }
      @include sm() {
        width: 100%;
      }
      vertical-align: top;
      & > div {
        margin: $margin-standard / 2;
        h1 {
          border-bottom: 2px solid;
          padding-bottom: $margin-standard / 2;
        }
        .imgcontainer {
          position: relative;
          padding-bottom: 75%;
          & > div {
            width: 100%;
            height: 100%;
            position: absolute;
            background-size: cover;
          }
        }
      }
      a {
        display: inline-block;
        &:after {
          content: ",";
          display: inline-block;
          width: 0.5em;
        }
        &:last-child {
          &:after {
            content: "";
          } 
        }
      }
    }
  }


</style>